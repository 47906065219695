.file-menu-container .tooltip-popover {
  width: 100%;
}

.file-menu-container .editable .tooltip-popover {
  width: unset;
}

.context-menu-container .selected-menu-item,
.file-menu-container .selected-menu-item {
  color: var(--kg-primary) !important;
}

.context-menu-container .selected-menu-item svg {
  fill: var(--kg-primary) !important;
}