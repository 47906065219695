/* Faq Page Css */

.accordion {
  margin: 20px 0;
  display: table;
  width: 100%;
}

.accordion__item {
  border: 0px;
  margin: 10px 0;
  transition: 0.25s;
}

.accordion__button {
  color: #352f36;
  cursor: pointer;
  padding: 13px 15px 13px 40px;
  width: 100%;
  text-align: left;
  border: none;
  background: #fff;
  position: relative;
}

.accordion__button:hover {
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto;
}

.accordion__button[aria-expanded='true'] {
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
  top: -5px;
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}
